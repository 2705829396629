import { render, staticRenderFns } from "./VgmUploadPop.vue?vue&type=template&id=078c4902"
import script from "./VgmUploadPop.vue?vue&type=script&lang=js"
export * from "./VgmUploadPop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/kmtcadmin/myagent/_work/15/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('078c4902')) {
      api.createRecord('078c4902', component.options)
    } else {
      api.reload('078c4902', component.options)
    }
    module.hot.accept("./VgmUploadPop.vue?vue&type=template&id=078c4902", function () {
      api.rerender('078c4902', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/trans/popup/VgmUploadPop.vue"
export default component.exports